import '@usb-shield/react-grid/dist/library/styles/index.css'
import { createRef, useEffect, useRef, useState } from 'react'
import { signOut, useSession } from 'next-auth/react'
import Link from 'next/link'
import { LoginModal } from '@/modules/login-modal/LoginModal'
import { PageHeaderMenuItem } from './pageHeader.model'
import USBButton from '@usb-shield/react-button'
import { USBColumn } from '@usb-shield/react-grid'
import USBLink from '@usb-shield/react-link'
import pageHeaderStyles from './pageHeader.module.scss'
import { showSuccessToast } from '@/utils/toast/toast'
import styles from './desktopNavBar.module.scss'
import { useQueryClient } from '@tanstack/react-query'
import { useRouter } from 'next/router'
import RegionPicker from '@/components/RegionPicker/RegionPicker'
import { USBIconProfile, USBIconClose } from '@usb-shield/react-icons'
import focusTrap from '@/utils/focus-trap/focus-trap'
import * as TealiumHandlers from '@/modules/tealium/utilities/events/clickHandlers'
import { InputSearch } from '@/components/InputSearch/InputSearch'
import { useSearchBarUtil } from './util/SearchBarUtil'
import { ProfileDropdownMenu } from '@/components/PageHeader/components/ProfileDropdownMenu'
import ChallengeWidgetModal from '@/modules/challenge-widget/ChallengeWidget'
import { getLastStepupDuration } from '@/utils/auth-widget/auth-widget'
import { fetchAccessibleProductLink } from '@/utils/products/global-element-product-access-check'
import { useCookies } from 'react-cookie'

const isElavon: boolean = process.env.SITE_BRAND === 'elavon'
export const DesktopNavBar = ({
  menuItems,
  imageURL,
  mainmenuId,
  fullWidthLayout,
  regionOptions,
  isLoginSignUpDisabled,
  isSearchDisabled,
  authWidgetConfigData,
  allProductVersionsInfo,
}: any) => {
  const isElavon: boolean = process.env.SITE_BRAND === 'elavon'
  const featureMFA: boolean = process.env.FEATURE_MFA !== 'false'

  const { handleSearchKeyDown } = useSearchBarUtil()
  const queryClient = useQueryClient()
  const router = useRouter()
  const onLogoutClick = async () => {
    const overlay: any = document.querySelector<HTMLElement>(`.showOverlay`)
    overlay.classList.remove(pageHeaderStyles.darkMode)
    await signOut({ callbackUrl: '/', redirect: false })
    router.push('/').then()
    showSuccessToast('Logout successful')
    queryClient.removeQueries()
  }

  const { data: session, status } = useSession()
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const listMainMenu = useRef(null)
  const menuWrapperRef = useRef(null)
  const loginButtonRef = useRef()
  const [cookies] = useCookies(['locale'])

  let localeArr: any[] = []

  if (cookies.locale) {
    localeArr = cookies.locale.split('-')
  }

  // MFA Challenge Widget
  const [challengeModalIsOpen, setChallengeModalIsOpen] = useState<boolean>(false)
  const [loginModalIsOpen, setLoginModalIsOpen] = useState<boolean>(false)
  const [activityName, setActivityName] = useState<string>('')
  const [callBackUrl, setCallBackUrl] = useState<string>('')
  useEffect(() => {}, [challengeModalIsOpen, callBackUrl])

  const mfaWidgetProps = {
    challengeModalIsOpen: challengeModalIsOpen,
    setChallengeModalIsOpen: setChallengeModalIsOpen,
    callBackUrl: callBackUrl,
    setCallBackUrl: setCallBackUrl,
    loginModalIsOpen: loginModalIsOpen,
  }

  const showChallengeWidget = (url: string, activityName: string) => {
    setActivityName(activityName)
    setCallBackUrl(url)
    setChallengeModalIsOpen(true)
  }
  // End MFA Challenge Widget

  const handleMenuOpenCallback = () => {
    setIsMenuOpen(true)
    hideOverlayAndMenu()
  }

  const handleMenuCloseCallback = () => {
    setIsMenuOpen(false)
  }

  let mainMenuRef = useRef(mainmenuId?.mainMenuId) as any

  const headerDesktopBaseNavColumn = {
    span: null,
    spans: fullWidthLayout
      ? { xlarge: 16, large: 16, medium: 8, small: 4 }
      : { xlarge: 12, large: 16, medium: 8, small: 4 },
    offset: fullWidthLayout
      ? { xlarge: 0, large: 0, medium: 0, small: 0 }
      : { xlarge: 2, large: 0, medium: 0, small: 0 },
    display: 'block',
    padding: 'zero',
    align: 'start',
    justify: 'stretch',
  }

  const headerDesktopElavonNavColumn = {
    span: null,
    spans: { xlarge: 16, large: 16, medium: 8, small: 4 },
    offset: { xlarge: 0, large: 0, medium: 0, small: 0 },
    display: 'block',
    padding: 'zero',
    align: 'start',
    justify: 'stretch',
  }

  const headerDesktopNavColumn =
    process.env.SITE_BRAND !== 'elavon'
      ? headerDesktopBaseNavColumn
      : headerDesktopElavonNavColumn

  const fullWidthLayoutStyledBase = fullWidthLayout ? '' : styles.fullWidth

  const fullWidthLayoutStyledElavon = styles.fullWidth

  const fullWidthLayoutStyled =
    process.env.SITE_BRAND !== 'elavon'
      ? fullWidthLayoutStyledBase
      : fullWidthLayoutStyledElavon

  const logoAndTaglineStyledBase = fullWidthLayout
    ? styles.logoAndTaglineFullWidth
    : styles.logoAndTaglineContainer

  const logoAndTaglineStyledElavon = styles.logoAndTaglineFullWidth

  const logoAndTaglineStyled =
    process.env.SITE_BRAND !== 'elavon'
      ? logoAndTaglineStyledBase
      : logoAndTaglineStyledElavon

  const profileDetailsStyledBase = fullWidthLayout
    ? styles.profileDetailsFullWidth
    : styles.profileDetails

  const profileDetailsStyledElavon = styles.profileDetailsFullWidth

  const profileDetailsStyled =
    process.env.SITE_BRAND !== 'elavon'
      ? profileDetailsStyledBase
      : profileDetailsStyledElavon

  const hideOverlayAndMenu = () => {
    // Hide overlay and menu when clicked outside
    if (profileMenuArrowFlag) setProfileMenuArrowFlag(!profileMenuArrowFlag)
    const mainMenu: any = document.querySelector<HTMLElement>(`.showOverlay`)
    mainMenu.classList.remove(pageHeaderStyles.darkMode)
    const subMenu: any = document.querySelectorAll<HTMLElement>(`.subMenu`)
    const grandSubMenu: any =
      document.querySelectorAll<HTMLElement>(`.grandSubMenu`)
    const profileDropdown: any =
      document.querySelectorAll<HTMLElement>(`.profileDropdown`)
    for (const element of grandSubMenu) {
      element.style.display = 'none'
    }
    for (const subMenus of subMenu) {
      subMenus.classList.remove(styles.isVisible)
    }
    const parentMenuList = listMainMenu.current as any
    if (parentMenuList) {
      const menuList = parentMenuList.querySelectorAll('li')
      for (const parentMenu of menuList) {
        parentMenu.classList.remove(styles.upArrow)
      }
    }
    // Close Profile dropdown
    for (const dropdown of profileDropdown) {
      if (dropdown.classList.contains(styles.showDropdown)) {
        dropdown.classList.remove(styles.showDropdown)
      }
    }
    // Close Login Modal
    const loginModal: any =
      document.querySelectorAll<HTMLElement>(`.loginModal`)
    for (const modal of loginModal) {
      if (modal.classList.contains(styles.showLoginModal)) {
        modal.classList.remove(styles.showLoginModal)
        let loginButton = loginButtonRef.current as any
        loginButton.focus()
      }
    }
  }

  useEffect(() => {
    const escapeKeyPressed = (event: any) => {
      if (event.key === 'Escape') {
        hideOverlayAndMenu()
      }
    }
    document.addEventListener('keydown', escapeKeyPressed, false)
  }, [])

  const toggleSubMenu = (e: any, levelRef: any) => {
    if (profileMenuArrowFlag) setProfileMenuArrowFlag(!profileMenuArrowFlag)
    closeLoginModal()
    const parentMenuList = listMainMenu.current as any
    const subMenu: any = document.querySelectorAll<HTMLElement>(`.subMenu`)
    const grandSubMenu: any =
      document.querySelectorAll<HTMLElement>(`.grandSubMenu`)
    for (const element of grandSubMenu) {
      element.style.display = 'none'
    }
    const getSubMenu: any = document.querySelector<HTMLElement>(`.subMenu`)
    const menuLinks = e.currentTarget.nextElementSibling.querySelectorAll('li')
    const profileDropdown: any =
      document.querySelectorAll<HTMLElement>(`.profileDropdown`)
    for (const menuLink of menuLinks) {
      menuLink.classList.remove(styles.selected)
    }

    // Remove parent menu if no products available
    const getParentMenu: any =
      document.querySelectorAll<HTMLElement>(`.grandSubMenu`)
    for (const element of getParentMenu) {
      if (element.innerHTML) {
        // empty block
      
      } else {
        element.closest('li').remove()
      }
    }

    const level1Links = levelRef
    const parentMenu = e.currentTarget.closest('li')
    const siblingList = level1Links.current.nextElementSibling
    if (siblingList) {
      e.preventDefault()
      getSubMenu.style.height = 'auto'
      parentMenu.classList.toggle(styles.upArrow)
      siblingList.classList.toggle(styles.isVisible)
      const mainMenu: any = document.querySelector<HTMLElement>(`.showOverlay`)
      const menuList = parentMenuList.querySelectorAll('li')
      const currentParentMenu = e.currentTarget.closest('li')
      // Add overlay when menu clicked
      if (currentParentMenu.classList.contains(styles.upArrow)) {
        if (process.env.SITE_BRAND !== 'elavon') {
          mainMenu.classList.add(pageHeaderStyles.darkMode)
        }
      } else {
        mainMenu.classList.remove(pageHeaderStyles.darkMode)
      }
      for (const subMenus of subMenu) {
        if (subMenus != siblingList) {
          subMenus.classList.remove(styles.isVisible)
        }
      }
      // Toggle arrow for main menu's
      for (const parentMenu of menuList) {
        if (parentMenu != currentParentMenu) {
          parentMenu.classList.remove(styles.upArrow)
        }
      }
      // Set first submenu to be selected by default
      if (menuLinks[0]) {
        // set first link as selected if there is a subMenu
        const thisGrandSubMenu = menuLinks[0].querySelectorAll(`.grandSubMenu`)
        const hasGrandSubMenu = thisGrandSubMenu.length > 0

        if (hasGrandSubMenu) {
          thisGrandSubMenu[0].style.display = 'block'
          const parentHeight = menuLinks[0].parentElement.clientHeight
          const grandSubMenuHeight = thisGrandSubMenu[0].clientHeight

          menuLinks[0].classList.add(styles.selected)

          if (parentHeight > grandSubMenuHeight) {
            thisGrandSubMenu[0].style.height = parentHeight + 'px'
          }
          if (parentHeight < grandSubMenuHeight) {
            menuLinks[0].parentElement.style.height = grandSubMenuHeight + 'px'
          }
        }
      }
    }
    for (const dropdown of profileDropdown) {
      if (dropdown.classList.contains(styles.showDropdown)) {
        dropdown.classList.remove(styles.showDropdown)
      }
    }

    // Remove productSuite link if no products available
    const getProductSuiteList: any =
      document.querySelectorAll<HTMLElement>(`.product-suites`)
    for (const element of getProductSuiteList) {
      if (element.innerHTML) {
        // empty block
      
      } else {
        element.closest('li').remove()
      }
    }
  }

  const toggleGrandSubMenu = (e: any) => {
    if (profileMenuArrowFlag) setProfileMenuArrowFlag(!profileMenuArrowFlag)
    const isActiveClass = 'is-active'
    const grandSubMenu: any =
      document.querySelectorAll<HTMLElement>(`.grandSubMenu`)
    const level2Links = e.currentTarget
    const level2List = e.currentTarget.closest('li')
    const subMenu: any = document.querySelector<HTMLElement>(`.subMenu`)
    const getSubMenuHeight: any =
      document.querySelector<HTMLElement>(`.subMenu`)
    const menuLinks = e.currentTarget.nextElementSibling?.querySelectorAll('li')
    const parentElement = e.currentTarget.parentElement

    for (const el of parentElement.parentNode.childNodes) {
      el.classList.remove(styles.selected)
    }

    if (menuLinks) {
      for (const menuLink of menuLinks) {
        menuLink.classList.remove(styles.selected)
      }
    }
    const siblingList = level2Links.nextElementSibling
    if (siblingList && level2Links.nextElementSibling.innerHTML) {
      e.preventDefault()
      level2List.classList.add(styles.selected)
      siblingList.classList.toggle(isActiveClass)
      // Toggle grandSubMenu
      getSubMenuHeight.style.height = 'auto'
      for (const element of grandSubMenu) {
        element.style.display = 'none'

        if (element.classList.contains(isActiveClass)) {
          element.style.display = 'block'
          element.style.transform = 'none'
          element.classList.remove(isActiveClass)
          // Set dynamic height for subMenu and grandSubMenu
          if (element.offsetHeight <= getSubMenuHeight.offsetHeight) {
            element.style.height = getSubMenuHeight.offsetHeight + 'px'
            subMenu.style.height = getSubMenuHeight.offsetHeight + 'px'
          } else {
            const setHeight = element.offsetHeight + 'px'
            element.parentNode.parentNode.style.height = setHeight
          }
        }
      }
    }
  }

  const [profileMenuArrowFlag, setProfileMenuArrowFlag] = useState(false)

  const toggleProfileDetails = (e: any) => {
    const parentMenuList = listMainMenu.current as any
    if (parentMenuList) {
      const menuList = parentMenuList.querySelectorAll('li')
      // Toggle arrow for main menu's
      for (const parentMenu of menuList) {
        parentMenu.classList.remove(styles.upArrow)
      }
    }

    const subMenu: any = document.querySelectorAll<HTMLElement>(`.subMenu`)
    const profileDropdown: any =
      document.querySelector<HTMLElement>(`.profileDropdown`)
    const showOverlay: any = document.querySelector<HTMLElement>(`.showOverlay`)
    profileDropdown.classList.toggle(styles.showDropdown)
    if (subMenu) {
      for (const subMenus of subMenu) {
        if (
          showOverlay.classList.contains(pageHeaderStyles.darkMode) &&
          !subMenus.classList.contains(styles.isVisible)
        ) {
          showOverlay.classList.remove(pageHeaderStyles.darkMode)
        }
        if (!showOverlay.classList.contains(pageHeaderStyles.darkMode)) {
          if (process.env.SITE_BRAND !== 'elavon') {
            showOverlay.classList.add(pageHeaderStyles.darkMode)
          }
        }
        subMenus.classList.remove(styles.isVisible)
      }
    }
    setProfileMenuArrowFlag(!profileMenuArrowFlag)

    if (
      e.target.classList.contains(styles.closeLoginModal) ||
      e.target.classList.contains(styles.profileNameUpArrow) ||
      (e.target.classList.contains(styles.profileIconStyled) &&
        showOverlay.classList.contains(pageHeaderStyles.darkMode) &&
        e.currentTarget
          .closest('a')
          .classList.contains(styles.profileNameUpArrow))
    ) {
      showOverlay.classList.remove(pageHeaderStyles.darkMode)
    } else {
      if (process.env.SITE_BRAND !== 'elavon') {
        showOverlay.classList.add(pageHeaderStyles.darkMode)
      }
    }

    focusTrap('.profileDropdown')
  }

  const openLoginModal = () => {
    setLoginModalIsOpen(true)
    const subMenu: any = document.querySelectorAll<HTMLElement>(`.subMenu`)
    if (subMenu) {
      for (const subMenus of subMenu) {
        subMenus.classList.remove(styles.isVisible)
      }
    }

    const parentMenuList = listMainMenu.current as any
    if (parentMenuList) {
      const menuList = parentMenuList.querySelectorAll('li')
      for (const parentMenu of menuList) {
        parentMenu.classList.remove(styles.upArrow)
      }
    }

    const showOverlay: any = document.querySelector<HTMLElement>(`.showOverlay`)
    showOverlay.classList.remove(pageHeaderStyles.darkMode)
    const loginModal: any = document.querySelector<HTMLElement>(`.loginModal`)
    loginModal.classList.add(styles.showLoginModal)

    focusTrap('.loginModal')

    if (!featureMFA) {
      const userLoginField = loginModal.querySelector(
        '#input_login_modal_username'
      )
      userLoginField.focus()
    }

    if (process.env.SITE_BRAND !== 'elavon') {
      showOverlay.classList.toggle(pageHeaderStyles.darkMode)
    }

    TealiumHandlers.displayLoginModalHandler()
  }

  const closeLoginModal = () => {
    const loginModal: any = document.querySelector<HTMLElement>(`.loginModal`)
    if (loginModal && loginModal.classList.contains(styles.showLoginModal)) {
      let loginButton = loginButtonRef.current as any
      loginButton.focus()
    }

    loginModal?.classList?.remove(styles.showLoginModal)
    const showOverlay: any = document.querySelector<HTMLElement>(`.showOverlay`)
    showOverlay?.classList?.remove(pageHeaderStyles.darkMode)
  }

  const getFirstLevelMenuItemStyles = (levelMenuItem: PageHeaderMenuItem) => {
    return levelMenuItem?.hasSubMenus
      ? styles.parentMenuLink
      : `${styles.parentMenuLinkNoSubMenu}`
  }

  const getSecondLevelMenuItemStyles = (levelMenuItem: PageHeaderMenuItem) => {
    return `${
      process.env.SITE_BRAND === 'elavon'
        ? styles.dropDownSubMenuForElavon
        : styles.dropDownSubMenu
    } 
      ${levelMenuItem.hasSubMenus ? '' : styles.whiteBGColor}`
  }

  let renderMobileMenuItems: any
  if (menuItems) {
    renderMobileMenuItems = menuItems?.map(function(
      firstLevelMenuItem: PageHeaderMenuItem,
      firstLevelItemIndex: number
    ) {
      return (
        <li className={styles.parentMenu} key={firstLevelMenuItem?.id}>
          <a
            href={`${firstLevelMenuItem?.url}` || '#'}
            className={getFirstLevelMenuItemStyles(firstLevelMenuItem)}
            ref={mainMenuRef.current[firstLevelItemIndex]}
            key={firstLevelMenuItem?.id}
            data-testid={`toggleSubMenu${firstLevelItemIndex}`}
            aria-label={firstLevelMenuItem.value}
            onClick={(e: any) => {
              if (firstLevelMenuItem?.hasSubMenus) {
                toggleSubMenu(e, mainMenuRef.current[firstLevelItemIndex])
              }
            }}
          >
            {firstLevelMenuItem.value}{' '}
          </a>
          <ul
            className={`${styles.subMenuList} ${styles.show} subMenu`}
            onMouseLeave={hideOverlayAndMenu}
          >
            {firstLevelMenuItem?.hasSubMenus &&
              firstLevelMenuItem?.menuItems?.items?.map(function (
                secondLevelMenuItem: PageHeaderMenuItem
              ) {
                return (
                  <li
                    className={getSecondLevelMenuItemStyles(
                      secondLevelMenuItem
                    )}
                    data-testid="submenu"
                    key={secondLevelMenuItem.id}
                  >
                    {secondLevelMenuItem.hasSubMenus ? (
                      <a
                        href={`${secondLevelMenuItem.url}` || '#'}
                        className={
                          styles.subMenuLink +
                          ' ' +
                          styles.grandSubMenuParentLink
                        }
                        data-testid="toggleGrandSubMenu"
                        onClick={(e: any) => {
                          toggleGrandSubMenu(e)
                        }}
                      >
                        {secondLevelMenuItem.value}
                      </a>
                    ) : (
                      <Link href={`${secondLevelMenuItem.url}` || '#'}>
                        <a
                          className={styles.subMenuLink}
                          data-testid="toggleGrandSubMenu"
                          onClick={(e: any) => {
                            toggleGrandSubMenu(e)
                            hideOverlayAndMenu()
                          }}
                          href={`${secondLevelMenuItem.url}` || '#'}
                        >
                          {secondLevelMenuItem.value}
                        </a>
                      </Link>
                    )}

                    {secondLevelMenuItem?.hasSubMenus ? (
                      <ul
                        className={`${styles.grandSubMenuList} ${styles.drop} grandSubMenu`}
                      >
                        {secondLevelMenuItem?.hasSubMenus &&
                          secondLevelMenuItem?.menuItems?.items?.map(function (
                            thirdLevelMenuItem: PageHeaderMenuItem,
                            thirdLevelItemIndex: number
                          ) {
                            const superChild =
                              thirdLevelMenuItem?.url?.split('/')
                            const superChildPathDepth = superChild?.length || 0
                            const superChildVersion = superChild
                              ? superChild[superChild.length - 1]
                              : ''
                            const superChildProductSlug = superChild
                              ? superChild[superChild.length - 2]
                              : ''
                            let superChildProductLink = thirdLevelMenuItem?.url
                            if (
                              thirdLevelMenuItem.value &&
                              superChildPathDepth - 1 > 2
                            ) {
                              superChildProductLink =
                                fetchAccessibleProductLink(
                                  superChildVersion,
                                  superChildProductSlug,
                                  allProductVersionsInfo,
                                  localeArr[1] || 'us'
                                )
                            }
                            if (superChildProductLink) {
                              if (featureMFA && session && getLastStepupDuration(authWidgetConfigData.MFA_WIDGET_CHALLENGE_INTERVAL_SECONDS)) {
                                if (
                                  superChildProductLink == '/dashboard/usage'
                                ) {
                                  superChildProductLink = ''
                                }
                              }

                              const thiredLevelLink = (
                                <a
                                  className={styles.grandSubMenuLink}
                                  onClick={() => {
                                    hideOverlayAndMenu()
                                    if (featureMFA && session && authWidgetConfigData) {
                                      if (
                                        thirdLevelMenuItem.url ==
                                        '/dashboard/usage' && getLastStepupDuration(authWidgetConfigData.MFA_WIDGET_CHALLENGE_INTERVAL_SECONDS)
                                      ) {
                                        showChallengeWidget(
                                          thirdLevelMenuItem.url, 'Usage'
                                        )
                                      }
                                    }
                                  }}
                                >
                                  {thirdLevelMenuItem.value}
                                </a>
                              )

                              return (
                                <li key={thirdLevelItemIndex}>
                                  {superChildProductLink && (
                                    <Link
                                      href={`${superChildProductLink}` || '#'}
                                    >
                                      {thiredLevelLink}
                                    </Link>
                                  )}
                                  {!superChildProductLink && (
                                    <>{thiredLevelLink}</>
                                  )}
                                  <ul className={superChildProductSlug}>
                                    {thirdLevelMenuItem?.hasSubMenus &&
                                      thirdLevelMenuItem?.menuItems?.items?.map(
                                        function (
                                          fourthLevelMenuItem: PageHeaderMenuItem,
                                          fourthLevelItemIndex: number
                                        ) {
                                          const subChild =
                                            fourthLevelMenuItem?.url?.split('/')
                                          const subChildPathDepth =
                                            subChild?.length || 0
                                          const subChildVersion = subChild
                                            ? subChild[subChild.length - 1]
                                            : ''
                                          const subChildProductSlug = subChild
                                            ? subChild[subChild.length - 2]
                                            : ''
                                          let subChildProductLink =
                                            fourthLevelMenuItem?.url
                                          if (
                                            fourthLevelMenuItem.value &&
                                            subChildPathDepth - 1 > 2
                                          ) {
                                            subChildProductLink =
                                              fetchAccessibleProductLink(
                                                subChildVersion,
                                                subChildProductSlug,
                                                allProductVersionsInfo,
                                                localeArr[1] || 'us'
                                              )
                                          }
                                          if (subChildProductLink) {
                                            return (
                                              <li key={fourthLevelItemIndex}>
                                                <Link
                                                  href={
                                                    `${subChildProductLink}` ||
                                                    '#'
                                                  }
                                                >
                                                  <a
                                                    className={
                                                      styles.greatGrandSubMenuLink
                                                    }
                                                    onClick={hideOverlayAndMenu}
                                                    href={
                                                      `${subChildProductLink}` ||
                                                      '#'
                                                    }
                                                  >
                                                    {fourthLevelMenuItem.value}
                                                  </a>
                                                </Link>
                                              </li>
                                            )
                                          }
                                        }
                                      )}
                                  </ul>
                                </li>
                              )
                            }
                          })}
                      </ul>
                    ) : null}
                  </li>
                )
              })}
          </ul>
        </li>
      )
    })
  }

  const firstInputRef = useRef<HTMLInputElement>(null)

  const firstName = session?.decodedAccessToken?.firstName || ''
  const lastName = session?.decodedAccessToken?.lastName

  return (
    <>
      {/* render MFA Challenge widget modal */}
      {challengeModalIsOpen && (
        <ChallengeWidgetModal
          callBackUrl={callBackUrl}
          challengeModalIsOpen={challengeModalIsOpen}
          setChallengeModalIsOpen={setChallengeModalIsOpen}
          authWidgetConfigData={authWidgetConfigData}
          activityName={activityName}
        ></ChallengeWidgetModal>
      )}
      <div
        className="showOverlay"
        data-testid="hideOverlayAndMenu"
        onClick={hideOverlayAndMenu}
      ></div>
      <USBColumn
        layoutOpts={headerDesktopNavColumn}
        addClasses={fullWidthLayoutStyled}
      >
        <nav>
          <div className={styles.menuWrapper} ref={menuWrapperRef}>
            <div className={logoAndTaglineStyled}>
              <USBLink href={'/'} addClasses={styles.profileLink}>
                <img src={imageURL} alt={process.env.SITE_BRAND + ' logo'} />
              </USBLink>
            </div>
            <div className={`${styles.navMenuItems}`}>
              <ul
                className={`${styles.mainMenu} ${
                  fullWidthLayout ? styles.mainMenuFullWidth : ``
                } main`}
                ref={listMainMenu}
              >
                {renderMobileMenuItems}
              </ul>
              {process.env.FEATURE_GEOLOCATION && (
                  <RegionPicker
                    options={regionOptions}
                    menuIsOpen={isMenuOpen}
                    handleMenuOpenCallback={handleMenuOpenCallback}
                    handleMenuCloseCallback={handleMenuCloseCallback}
                  />
                )}
            </div>
            {!isSearchDisabled && router.pathname !== '/search' && (
              <InputSearch
                inputID="edp_search_input"
                inputName="query_string"
                handleSearchKeyDown={handleSearchKeyDown}
              />
            )}
            {status !== 'loading' &&
              (!session ? (
                <div className={styles.navButton}>
                  {!isLoginSignUpDisabled && router.pathname !== '/login' && (
                    <>
                      <USBButton
                        variant="loud"
                        size="small"
                        id="login-btn"
                        addClasses={styles.loginButton}
                        forwardedRef={loginButtonRef}
                        handleClick={() => openLoginModal()}
                        dataTestId="onLoginClick"
                      >
                        {' '}
                        Log in{' '}
                      </USBButton>
                      <div
                        className={`${
                          featureMFA ? styles.loginModalMFA : styles.loginModal
                        } loginModal`}
                      >
                        <USBButton
                          type="button"
                          id="close-login-modal"
                          dataTestId="close-login-modal"
                          ariaLabel="Close login modal"
                          addClasses={styles.closeLoginModal}
                          handleClick={() => closeLoginModal()}
                          ref={firstInputRef}
                        >
                          <span aria-hidden="true">×</span>
                        </USBButton>
                        <LoginModal
                          overlayDarkModeClass={pageHeaderStyles.darkMode}
                          authWidgetConfigData={authWidgetConfigData}
                          mfaWidgetProps={mfaWidgetProps}
                        ></LoginModal>
                      </div>
                    </>
                  )}
                </div>
              ) : (
                <div className={profileDetailsStyled}>
                  <USBLink href={'#'} addClasses={styles.profileLink}>
                    <a
                      className={
                        profileMenuArrowFlag
                          ? styles.profileNameUpArrow
                          : styles.profileName
                      }
                      href="#"
                      role="button"
                      data-testid="toggleProfileDetails"
                      onClick={(e: any) => {
                        e.preventDefault()
                        toggleProfileDetails(e)
                      }}
                    >
                      {isElavon ? (
                        <USBIconProfile
                          colorVariant="grey80"
                          size={24}
                          addClasses={styles.profileIcon}
                        />
                      ) : (
                        <span className={styles.profileIconStyled}></span>
                      )}

                      {(!isElavon ? Array.from(firstName)[0] : firstName) +
                        ' ' +
                        lastName}
                    </a>
                  </USBLink>
                  <div className={`${styles.profileDropdown} profileDropdown`}>
                    <div className={styles.profileUserSection}>
                      <div className={styles.profileUserInfo}>
                        <p className={styles.profileUserName}>
                          {firstName + ' ' + lastName}
                        </p>
                        <p className={styles.profileUserEmail}>
                          {session?.decodedAccessToken?.email}
                        </p>
                      </div>
                      <USBButton
                        type="button"
                        id="close-login-modal"
                        dataTestId="close-profile-modal"
                        addClasses={styles.closeLoginModal}
                        handleClick={(e: any) => {
                          e.preventDefault()
                          toggleProfileDetails(e)
                        }}
                      >
                        <span aria-hidden="true">
                          {!isElavon ? (
                            'x'
                          ) : (
                            <USBIconClose
                              colorVariant="grey80"
                              size={24}
                              addClasses={styles.profileIcon}
                            />
                          )}
                        </span>
                      </USBButton>
                    </div>
                    <div className={styles.profilemenu}>
                      <div className={styles.profileDropdownLinks}>
                        <ProfileDropdownMenu
                          hideOverlayAndMenu={hideOverlayAndMenu}
                          onLogoutClick={onLogoutClick}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </nav>
      </USBColumn>
    </>
  )
}